import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CreateFlashCard from './CreateFlashCard'; // Import the new component
import './FileUpload.css';

function FileUpload() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [message, setMessage] = useState('');
  const [extractedText, setExtractedText] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a file first!');
      return;
    }

    setLoading(true);
    setMessage('Uploading...');

    const formData = new FormData();
    formData.append('file', file);
    const userId = localStorage.getItem('userId'); // get user id from local storage
    formData.append('user_id', userId); //append user id to form data

    try {
      const response = await axios.post(`${API_BASE_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('File upload response:', response.data);
      if (response.data.success) {
        setMessage('File uploaded successfully!');
        console.log('Extracting text from:', response.data.file.file_path); // Log the file path for extraction
        await handleExtractText(response.data.file.file_path); //extract text after upload
      }
    } catch (error) {
      console.error('Upload error:', error);
      setMessage('Error uploading file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleExtractText = async (filePath) => {
    setLoading(true);
    setMessage('Extracting text...');

    try {
      const response = await axios.post(`${API_BASE_URL}/api/extract`, { filePath });
      if (response.data.success) {
        setExtractedText(response.data.text);
        setMessage('Text extracted successfully!');
        await handleGenerateFlashcards(response.data.text); // Generate flashcards after extraction
      } else {
        setMessage('Error extracting text');
      }
    } catch (error) {
      console.error('Error extracting text:', error);
      setMessage('Error extracting text');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateFlashcards = async (text) => {
    if (text.length > 1000) {
      setMessage('Text exceeds 1000 words limit. Please provide shorter content.');
      return;
    }
  const userId = localStorage.getItem('userId'); //get user ID from local storage
  
  console.log('User ID:', userId);
  console.log('Text length', text.length);
  setGenerating(true);

  try {
    const response = await axios.post(`${API_BASE_URL}/api/generateflashcards`, { text, user_id: userId });
    
    console.log('Request body:', { text, user_id: userId});
    
    if (response.data.success) {
      // Assuming response.data.flashcards contains the generated flashcards
      console.log('Generated flashcards:', response.data.flashcards);
      setMessage('Flashcards generate successfully!');
      // You can now pass these flashcards to CreateFlashCard or handle them as needed
    } else {
      setMessage('Error generating flashcards');
    }
  } catch (error) {
    console.error('Error generating flashcards:', error);
    setMessage('Error generating flashcards');
  } finally {
    setGenerating(false); //reset generating state
  }
};

  const GoDashboard = () => {
    navigate('/dashboard'); //Back to the home page.
 };

  return (
  <div className="upload-container">
    <h2>Upload File</h2>
    <button onClick={GoDashboard}>Back</button>
    <form onSubmit={handleSubmit}>
      <div className="upload-area">
        <label className="file-label">
          Choose File
          <input type="file" onChange={handleFileChange} style={{display: 'none'}} />
        </label>
      </div>
      <button type="submit" className="upload-button" disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
    </form>
    {extractedText && (
      <div className="preview-container">
        <h3>Extracted Text</h3>
        <textarea value={extractedText} readOnly rows="20" cols="100" />
      </div>
    )}
    {message && <p className="message">{message}</p>}
    {generating && <p>Generating Flashcards...</p>}
    <CreateFlashCard />
  </div>
);
}

export default FileUpload;
