import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useAutoLogout = (timeoutDuration = 900) => {
    const [timeLeft, setTimeLeft] = useState(timeoutDuration);
    const navigate = useNavigate();

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        navigate('/sign-in');
    }, [navigate]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    handleLogout(); // Log out when time's up
                    return 0;
                }
                return prev - 1; // Decrease time left
            });
        }, 1000); // Update every second

        const resetTimer = () => {
            setTimeLeft(timeoutDuration); // Reset to the specified timeout duration
        };

        // Event listener for user activity
        const events = ['click', 'mousemove', 'keypress', 'scroll'];
        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });

        return () => {
            clearInterval(timer);
            events.forEach((event) => {
                window.removeEventListener(event, resetTimer);
            });
        };
    }, [handleLogout, timeoutDuration]);

    return timeLeft; // Return the time left if needed
};

export default useAutoLogout;