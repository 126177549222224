import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function GameModeSelection() {
    const navigate = useNavigate();
    const [customAmount, setCustomAmount] = useState(1); // Default to 1
    const [totalFlashcards, setTotalFlashcards] = useState(0); // Total flashcards from the database
    const [error, setError] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchTotalFlashcards = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/flashcards?user_id=${userId}`);
            setTotalFlashcards(response.data.length); // Set total flashcards
        } catch (error) {
            console.error('Error fetching flashcards:', error);
        }
    }, [API_BASE_URL]); // Include API_BASE_URL if it can change

    useEffect(() => {
        fetchTotalFlashcards();
    }, [fetchTotalFlashcards]); // Include fetchTotalFlashcards in the dependency array

    const handleStartGame = () => {
        if (customAmount < 1 || customAmount > totalFlashcards) {
            setError(`Please enter a number between 1 and ${totalFlashcards}.`);
        } else {
            setError(''); // Clear any previous error
            navigate('/play', { state: { mode: 'custom', customAmount } });
        }
    };

    return (
        <div>
            <h2>Select Game Mode</h2>
            <button onClick={() => navigate('/play', { state: { mode: 'all' } })}>Shuffle All</button>
            <div>
                <label>
                    Custom Amount (1 - {totalFlashcards}):
                    <input
                        type="number"
                        value={customAmount}
                        onChange={(e) => setCustomAmount(Number(e.target.value))}
                        min="1"
                        max={totalFlashcards}
                    />
                </label>
                <button onClick={handleStartGame}>Start Game</button>
                {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
            </div>
        </div>
    );
}

export default GameModeSelection;
