import React from 'react';
import './Home.css'; // Import the CSS for styling

function Home() {
    const handleSignIn = () => {
        window.location.href = '/sign-in'; // Redirect to sign-in page
    };
    
    const handleSignUp = () => {
        window.location.href = '/sign-up'; // Redirect to sign-up page
    };

    return (
        <div className="home-container"> {/* Updated class name to match CSS */}
            <h2>Welcome to FlashCard AI</h2>
            <p>Your one-stop solution for creating and managing flashcards.</p>
            <button className="home-button" onClick={handleSignIn}>Sign In</button>
            <button className="home-button" onClick={handleSignUp}>Sign Up</button>
        </div>
    );
}

export default Home;