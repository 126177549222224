import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import FlashCard from './FlashCard';
import CreateFlashCard from './CreateFlashCard';
import { useNavigate } from 'react-router-dom';

function FlashCardList() {
    console.log('Flashcard list component rendered');
    const [flashcards, setFlashcards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [flippedCards, setFlippedCards] = useState({}); // Track flipped state for each card
    const [selectedCards, setSelectedCards] = useState(new Set());
    const navigate = useNavigate();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchFlashcards = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/flashcards?user_id=${userId}`);
            console.log('Fetched flashcards:', response.data); // Log the fetched data
            setFlashcards(response.data);
            setLoading(false); // Ensure loading is set to false after fetching
        } catch (error) {
            console.error('Error fetching flashcards:', error);
            setError('Error fetching flashcards');
        } finally {
            setLoading(false); // Ensure loading is set to false even on error
        }
    }, [API_BASE_URL]);

    useEffect(() => {
        fetchFlashcards();
    }, [fetchFlashcards]);

    const handleFlashCardCreated = (newCard) => {
        console.log('New card added:', newCard); // Log the new card
        if (newCard.flashcard) {
            setFlashcards((prevCards) => [...prevCards, newCard.flashcard]); // Add the new flashcard
        } else {
            console.error('New card does not contain flashcard property:', newCard);
        }
    };

    const GoDashboard = () => {
        navigate('/dashboard'); // Back to the home page.
    };

    const toggleSelectCard = (id) => {
        setSelectedCards((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(id)) {
                newSelected.delete(id); // Deselect the card
            } else {
                newSelected.add(id); // Select the card
            }
            return newSelected;
        });
    };

    const deleteSelectedCards = useCallback(async () => {
        const idsToDelete = Array.from(selectedCards); // Convert Set to Array
        try {
            await Promise.all(idsToDelete.map(id => 
                axios.delete(`${API_BASE_URL}/api/flashcards/${id}`)
            ));
            setFlashcards((prevCards) => prevCards.filter(card => !idsToDelete.includes(card.id))); // Remove deleted cards from state
            setSelectedCards(new Set()); // Clear selected cards
            console.log(`Flashcards with ids ${idsToDelete.join(', ')} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting flashcards:', error);
        }
    }, [selectedCards, API_BASE_URL]);

    console.log('Current flashcards:', flashcards); // Log the current flashcards
    if (loading) return <p>Loading flashcards...</p>;
    if (error) return <p>{error}</p>;
    if (flashcards.length === 0) return (
        <div>
            <p>No flashcards available. Please add some.</p>
            <button onClick={GoDashboard}>Back</button>
        </div>
    );

    const toggleFlip = (id) => {
        setFlippedCards((prev) => ({
            ...prev,
            [id]: !prev[id], // Toggle the flip state for the specific card
        }));
    };

    return (
        <div>
            <h2>My Flashcards</h2>
            <button onClick={GoDashboard}>Back</button>
            {flashcards.length === 0 && <CreateFlashCard onFlashCardCreated={handleFlashCardCreated} />}
            <div className="flashcard-list">
                {flashcards.map((card) => (
                    <div key={card.id} className="flashcard-container">
                        <input
                            type="checkbox" 
                            checked={selectedCards.has(card.id)} 
                            onChange={() => toggleSelectCard(card.id)}
                        />
                        <FlashCard 
                            card={card} 
                            isFlipped={flippedCards[card.id] || false} // Pass the flip state
                            onFlip={() => toggleFlip(card.id)} // Pass the toggle function
                        />
                    </div>
                ))}
            </div>
            <button onClick={deleteSelectedCards} disabled={selectedCards.size === 0}>Delete Selected</button>
        </div>
    );
}

export default FlashCardList;
