import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FlashCard from './FlashCard'; // Import the FlashCard component

function Play() {
    const location = useLocation();
    const navigate = useNavigate();
    const { mode, customAmount } = location.state || { mode: 'all', customAmount: 0 };

    const [flashcards, setFlashcards] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [reactionTimes, setReactionTimes] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [isFlipped, setIsFlipped] = useState(false); // Track if the card is flipped
    const [totalCards, setTotalCards] = useState(0); // Track total number of cards
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Use useCallback to memoize the fetchFlashcards function
    const fetchFlashcards = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/flashcards?user_id=${userId}`);
            let cards = response.data;
            if (mode === 'custom') {
                cards = cards.sort(() => Math.random() - 0.5).slice(0, customAmount);
            } else {
                cards = cards.sort(() => Math.random() - 0.5);
            }
            setFlashcards(cards);
            setTotalCards(cards.length); // Set total number of cards
        } catch (error) {
            console.error('Error fetching flashcards:', error);
        }
    }, [mode, customAmount, API_BASE_URL]); // Include API_BASE_URL in the dependencies

    useEffect(() => {
        fetchFlashcards();
    }, [fetchFlashcards]); // Include fetchFlashcards in the dependency array

    const handleAnswer = (isCorrect) => {
        const reactionTime = (Date.now() - startTime) / 1000; // Calculate reaction time in seconds
        setReactionTimes((prev) => [...prev, reactionTime]); // Update reaction times
        if (isCorrect) {
            setScore((prev) => prev + 1); // Increment score if correct
        }
        nextCard(); // Move to the next card after answering
    };

    const nextCard = () => {
        if (currentCardIndex < flashcards.length - 1) {
            setCurrentCardIndex((prev) => prev + 1);
            setStartTime(Date.now()); // Reset start time for the next card
            setIsFlipped(false); // Reset flip state for the next card to show front
        } else {
            endGame();
        }
    };

    const endGame = async () => {
        const userId = localStorage.getItem('userId');
        const totalReactionTime = Math.round(reactionTimes.reduce((a, b) => a + b, 0)); // Total reaction time in seconds
        
        try {
            await axios.post(`${API_BASE_URL}/api/scores`, {
                user_id: userId,
                score: score,
                total_reaction_time: totalReactionTime, // This will now be in seconds
                total_cards: totalCards, // Send total number of cards
            });
            alert(`Game Over! Your score: ${score}`);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error submitting score:', error);
        }
    };

    const handleQuit = () => {
        endGame(); // Call endGame to submit the score
    };

    if (flashcards.length === 0) return <p>Loading...</p>;

    const currentCard = flashcards[currentCardIndex];

    return (
        <div>
            <h2>Score: {score}</h2>
            <FlashCard 
                card={currentCard} 
                onFlip={() => setIsFlipped(prev => !prev)} // Toggle the flip state
                isFlipped={isFlipped} // Pass the isFlipped state
            />
            {/* Render buttons only when the card is flipped to the back */}
            {isFlipped && (
                <div className="answer-buttons">
                    <button onClick={() => handleAnswer(true)}>Correct</button>
                    <button onClick={() => handleAnswer(false)}>Incorrect</button>
                </div>
            )}
            <div className="navigation-buttons">
                <button onClick={handleQuit}>Quit</button>
            </div>
        </div>
    );
}

export default Play;
