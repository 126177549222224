import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SignIn.css'; // Import the CSS for styling

function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  console.log("API_BASE_URL is:", API_BASE_URL);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Attempting to sign in with', {username,password});
    // Handle sign-in logic here
    try {
        const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
          username,
          password,
        });
        console.log('Sign In Response:', response.data);
        if (response.data.token) {
          localStorage.setItem('token', response.data.token); // Store the token
          localStorage.setItem('userId', response.data.user.id); // Store user id
          localStorage.setItem('username', response.data.user.username); // Store user name
          navigate('/dashboard'); // Redirect to the dashboard on successful sign-in
        } else {
          setMessage('Invalid username or password');
        }
    } catch (error) {
        setMessage('Invalid username or password'); // Handle error
        console.error('Sign In Error:', error.response ? error.response.data : error);
      }
  };

  const handleBackToHome = () => {
    navigate('/'); //Back to the home page.
};

  return (
    <div className="signin-container">
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Sign In</button>
        <button type="button" onClick={handleBackToHome}>Back</button>
      </form>
      {message && <p className="error-message">{message}</p>}
    </div>
  );
}

export default SignIn;
