import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SignUp() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/api/auth/register`, {
                username,
                email,
                password,
            });
            console.log('Sign-up info:', response.data);
            setMessage('Registration successful! You can now sign in.');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
            setMessage('Error: ' + errorMessage);
        }
    };
    
    const handleBackToHome = () => {
        navigate('/'); //Back to the home page.
    }

    return (
        <div>
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Sign Up</button>
                <button onClick={handleBackToHome}>Back</button>
            </form>
            {message && <p>{message}</p>}
            
        </div>
    );
}

export default SignUp;
